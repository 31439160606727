<template>
  <div class="home">
    <v-row>
      <v-col :cols="12" align="center">
        <GoogleLogin :params="params" :renderParams="renderParams" :onSuccess="onSuccess" :onFailure="onFailure"></GoogleLogin>
      </v-col>
    </v-row>
    <v-snackbar
            v-model="snackbar"
            top
        >
            {{ snackbarText }}
            <v-btn
                color="pink"
                text
                @click="snackbar = false"
            >
            Close
            </v-btn>
        </v-snackbar>
  </div>
</template>

<script>
// @ is an alias to /src
import GoogleLogin from 'vue-google-login';

export default {
  name: 'Home',
  components: {
    GoogleLogin,
  },
  data: () => ({
    snackbar: false,
    snackbarText: "",
    params: {
        // client_id: "572156098738-vj6a63q46shh1ui1j9vqp87kfvp07lhs.apps.googleusercontent.com" // moka prod
        // client_id: "572156098738-rsn9u7l99vpd8lcqnui2a5og6rh460hs.apps.googleusercontent.com" // moka local
        // client_id: "989540891168-u1adl9skkdok6nkk3tuo68acrbd46kal.apps.googleusercontent.com" // fatih
        client_id: "717865490373-u20nuajokh3410vq58vac2agngldpss0.apps.googleusercontent.com" //go-store prod
    },
    // only needed if you want to render the button with the google ui
    renderParams: {
        width: 250,
        height: 50,
        longtitle: true
    }
  }),
  mounted() {
  },
  methods: {
    onSuccess(googleUser) {
        let self = this
        let id_token = googleUser.getAuthResponse().id_token
        
        this.$http.post(this.$config.apiEndpoint + '/auth/google-success-login/', {
                idToken: id_token,
            }).then(response => {
                if (response.status === 200 && 'token' in response.data.data) {
                    self.$session.start()
                    self.$session.set('token ', response.data.data.token)
                    localStorage.setItem('token',response.data.data.token)
                    self.$http.defaults.headers.common['Authorization'] = response.data.data.token
                    if(this.$route.query.nextUrl != null){
                        // this.$router.push(this.$route.params.nextUrl)
                        window.location = this.$route.query.nextUrl;
                    } else {
                        // this.$router.push("/")
                        window.location = "/";
                    }
                    
                }else{
                    this.showProgress = false
                    this.snackbarText = response.data.message;
                    this.snackbar = true;
                }
            }).catch(e => {
                this.showProgress = false
                try{
                  this.snackbarText = e.response.data.message;
                } catch (e) {
                  this.snackbarText = "Email not registered, please ask SRE to register your email";
                }
                this.snackbar = true;
            })
    },
    onFailure(googleUser) {
        console.log(googleUser);
    }
  }
}
</script>
